import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "../components/Container"
import SEONO from "../components/SEO-notemplate"
import WorkGrid from "../components/Work/WorkGrid"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class Index extends React.Component {
  render() {
    const allWork = this.props.data.contentfulHomepage.pageOrder
    const homepage = this.props.data.contentfulHomepage

    return (
      <Layout>
        <SEONO title="Becky Fitzgerald - Graphic Designer in Bristol" description="I am Becky Fitzgerald, a freelance graphic designer currently living in Bristol."/>

        <div className="introduction">
          <Container>
            <div className="heading">
              {homepage.heading
                ? documentToReactComponents(homepage.heading.json)
                : `Hey. I'm Becky! I'm a graphic designer in Bristol.`}
            </div>
          </Container>
        </div>

        <div id="featured-work">
          <WorkGrid allWork={allWork} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomepage(title: { eq: "Homepage" }) {
      id
      title
      heading {
        json
      }
      pageOrder {
        id
        title
        slug
        featuredImage {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
