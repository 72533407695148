import React from "react"
import WorkTile from "./WorkTile"

export default ({ allWork }) => {
  return (
    <div className="work-grid">
      {allWork.map((work) => {
        return <WorkTile work={work} key={work.slug} />
      })}
    </div>
  )
}
