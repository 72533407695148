import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ work }) => {
  return (
    <div className="tile tile__work">
      <Link to={`/work/${work.slug}`}>
        <Img
          className="featured-image"
          alt={work.title}
          fluid={{ ...work.featuredImage.fluid, aspectRatio: 1 }}
          backgroundColor={'#f9f9f9'}
        />
        <span className="inner">
          <span className="helper"></span>
          <span className="info">
            <h2>{work.title}</h2>
          </span>
        </span>
      </Link>
    </div>
  )
}
